// globalMixins.js
// export const globalMixins = {
//     methods: {
//       formatMoney(value, currency = 'USD') {
//         return new Intl.NumberFormat('es-ES', { style: 'currency', currency: currency }).format(value);
//       }
//     }
// };

export const globalMixins = {
  methods: {
    formatMoney(value, currency = 'HNL') {
      let formattedValue = Number(value).toFixed(2); // Asegura que siempre tenga dos decimales
      

      switch (currency) {
        case 'HNL':
          return `L${formattedValue}`; // Formato para Lempira
        case 'USD':
          return `$${formattedValue}`; // Formato para Dólar
        default:
          return `${currency}${formattedValue}`; // Formato por defecto para otras monedas
      }
    }
  }
};